import React from 'react';
import styled from 'styled-components';
import placeholderPic from '../assets/images/product-placeholder-01.jpg';
import cheesesSubHeader from '../assets/images/blues-sub-header.jpg';
import SEO from '../components/SEO';

const cheeseRange = require('../assets/data/cheese-range.json');

const CheesesHeader = styled.div`
  background: url(${cheesesSubHeader});
  background-size: cover;
  background-position: bottom center;
  min-height: 200px;
  margin-bottom: 40px;
  vertical-align: middle;
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    margin-bottom: 10px;
  }

  div {
    text-align: left;
    max-width: 1100px;
    width: 100%;
  }

  h3 {
    font-family: BrandFont;
    font-size: 3.5rem;
    margin-bottom: 0px;
    padding-bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    display: inline-block;
    color: white;
  }
`;

const Category = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  right: 0px;
`;

export default function CheesesPage() {
  const sortedCheeses = Array.from(cheeseRange);
  sortedCheeses.sort((cheeseA, cheeseB) =>
    cheeseA.name.localeCompare(cheeseB.name)
  );

  const [cheeseTypes, setCheeseTypes] = React.useState([
    'cow',
    'sheep',
    'goat',
  ]);
  const [filteredResults, setFilteredResults] = React.useState(sortedCheeses);

  const handleChange = (e) => {
    const savedEvent = e;
    const savedTarget = e.currentTarget;
    // e.persist();
    console.log(savedTarget);
    console.log(savedTarget.checked);
    if (savedTarget.checked) {
      setCheeseTypes((cheeseTypes) => [...cheeseTypes, savedTarget.value]);
    } else {
      setCheeseTypes(cheeseTypes.filter((item) => item !== savedTarget.value));
    }
    console.log(cheeseTypes);
  };

  console.log('cheeseTypes initial', cheeseTypes);

  React.useEffect(() => {
    let results = [];
    results = sortedCheeses.filter((cheese) =>
      cheeseTypes.includes(cheese.type)
    );
    setFilteredResults(results);
  }, [cheeseTypes]);

  return (
    <>
      <SEO title="Our Cheeses" />
      <CheesesHeader>
        <div className="content-mw-1100">
          <h3>Our Cheeses</h3>
        </div>
      </CheesesHeader>

      <div className="cheeses-range-cols content-mw-1100 ">
        <div className="sidebar">
          <label className="label-cow">
            <input
              name="cow"
              type="checkbox"
              value="cow"
              checked={cheeseTypes.includes('cow')}
              onChange={handleChange}
            />{' '}
            <span>Cows</span>
          </label>
          <label className="label-sheep">
            <input
              name="sheep"
              type="checkbox"
              value="sheep"
              checked={cheeseTypes.includes('sheep')}
              onChange={handleChange}
            />{' '}
            <span>Sheep</span>
          </label>
          <label className="label-goat">
            <input
              name="goat"
              type="checkbox"
              value="goat"
              checked={cheeseTypes.includes('goat')}
              onChange={handleChange}
            />{' '}
            <span>Goats</span>
          </label>
        </div>
        {filteredResults.length < 1
          ? 'Please ensure a cheese category is selected in order to view our range.'
          : ''}
        <div className="three-col-grid grid-no-scale cheeses-range">
          {filteredResults.map((cheese, iCheese) => (
            <div className="card" key={iCheese}>
              <div>
                <div>
                  <Category>
                    <img
                      src={`/images/category-badge-${cheese.type}.png`}
                      alt=""
                    />
                  </Category>
                  <a href={`/cheeses/${cheese.slug}`}>
                    <img
                      src={
                        cheese.image
                          ? `/images/products/${cheese.image}`
                          : placeholderPic
                      }
                      alt=""
                    />
                    <span>{cheese.name}</span>
                  </a>
                  <br />
                </div>
              </div>
              <p className="price-per-kg">
                <a href={`/cheeses/${cheese.slug}`} className="buy-now-link">
                  View Details
                </a>
                {/* <span>
                  €{parseInt(cheese.pricePerKg / 100).toFixed(2)} per/kg
                </span> */}
              </p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
